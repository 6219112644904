import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  Button,
} from "reactstrap";
import { AuthContext } from "../context/AuthContext";

function NavigationBar() {
  const { isAuthenticated, logout } = useContext(AuthContext);

  return (
    <Navbar className="navbar-horizontal navbar-light bg-white" expand="lg">
      <Container>
        <NavbarBrand href="/">Decryptor</NavbarBrand>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink to="/decryptor" tag={Link}>
              Decrypt
            </NavLink>
          </NavItem>
          {isAuthenticated ? (
            <>
              <NavItem>
                <NavLink to="/profile" tag={Link}>
                  Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <Button color="danger" onClick={logout}>
                  Logout
                </Button>
              </NavItem>
            </>
          ) : (
            <>
              <NavItem>
                <NavLink to="/login" tag={Link}>
                  Login
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/register" tag={Link}>
                  Register
                </NavLink>
              </NavItem>
            </>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
