import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Alert,
  Card,
  CardBody,
} from "reactstrap";
import axios from "axios";

function Decryptor() {
  const [link, setLink] = useState("");
  const [data, setData] = useState("");
  const [fullData, setFullData] = useState("");
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [credits, setCredits] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/demo", { link });
      setData(response.data.preview);
      setFullData(response.data.full);
    } catch (err) {
      setError("Failed to decrypt the link");
    }
  };

  const handleUnlock = async () => {
    try {
      const response = await axios.post(
        "/api/auth/unlock",
        { link },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setIsUnlocked(true);
      setFullData(JSON.stringify(JSON.parse(response.data.full), null, 2));
      setCredits(response.data.new_balance);
      setError("");
      setSuccess(
        "Content unlocked successfully with 1 credit, you have " +
          response.data.new_balance +
          " credits remaining"
      );
    } catch (err) {
      setError("Failed to unlock the content");
    }
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col lg="8">
          <h1 className="text-center">Welcome to Decryptor</h1>
          <p className="text-center">Paste your link below to decrypt it</p>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                type="text"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder="Paste your link here"
              />
            </FormGroup>
            <Button type="submit" color="primary" block>
              Decrypt
            </Button>
          </Form>
          {error && (
            <Alert color="danger" className="mt-3">
              {error}
            </Alert>
          )}
          {success && (
            <Alert color="success" className="mt-3">
              {success}
            </Alert>
          )}
          {data && (
            <Card className="mt-5">
              <CardBody>
                <pre>{isUnlocked ? fullData : `${data}...`}</pre>
                {!isUnlocked && (
                  <Button onClick={handleUnlock} color="success" block>
                    Unlock Full Text (1 Credit)
                  </Button>
                )}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Decryptor;
