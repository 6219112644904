import React, { useState, useEffect } from "react";
import App from "./App";

function AppLoader() {
  const [stylesLoaded, setStylesLoaded] = useState(false);

  useEffect(() => {
    const links = document.querySelectorAll('link[rel="stylesheet"]');
    let loadedCount = 0;

    const onStyleLoad = () => {
      loadedCount++;
      if (loadedCount === links.length) {
        setStylesLoaded(true);
      }
    };

    links.forEach((link) => {
      if (link.sheet) {
        // If the sheet is already loaded
        onStyleLoad();
      } else {
        // Add event listener if the sheet is not loaded yet
        link.addEventListener("load", onStyleLoad);
      }
    });

    // Fallback in case link elements don't load correctly
    const fallbackTimeout = setTimeout(() => {
      setStylesLoaded(true);
    }, 3000);

    return () => {
      clearTimeout(fallbackTimeout);
      links.forEach((link) => {
        link.removeEventListener("load", onStyleLoad);
      });
    };
  }, []);

  if (!stylesLoaded) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return <App />;
}

export default AppLoader;
