import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Container, Row, Col, Card, CardBody, Button, Alert } from "reactstrap";

function ProfilePage() {
  const [profile, setProfile] = useState({});
  const [credits, setCredits] = useState(0);
  const [error, setError] = useState("");
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get("/api/auth/profile", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setProfile(response.data);
        setCredits(response.data.credits);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          logout();
          navigate("/login");
        }
        setError("Error fetching profile");
        console.error("Error fetching profile", error);
      }
    };
    fetchProfile();
  }, [logout, navigate]);

  const handleAddCredits = async () => {
    try {
      const response = await axios.post(
        "/api/auth/credits/add",
        { amount: 10 },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setCredits(response.data.new_balance);
    } catch (error) {
      setError("Error adding credits");
      console.error("Error adding credits", error);
    }
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Profile</small>
              </div>
              {error && <Alert color="danger">{error}</Alert>}
              <div>
                <p>Username: {profile.username}</p>
                <p>Email: {profile.email}</p>
                <p>Email Confirmed: {profile.email_confirmed ? "Yes" : "No"}</p>
                <p>Credits: {credits}</p>
                <p>API Key: {profile.api_key}</p>
                <Button onClick={handleAddCredits} color="primary" block>
                  Add 10 Credits
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ProfilePage;
